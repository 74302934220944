import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import configureStore from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { translationMessages } from 'i18n';
import LanguageProvider from 'layouts/LanguageProvider';
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import { renderRoutes } from 'react-router-config';
import routes from 'routes';
import themeDefault from 'theme';
import GlobalStyle from 'global-styles';
import 'react-calendar/dist/Calendar.css';
import 'react-day-picker/lib/style.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useLocation } from 'react-router-dom';
import { ROUTENAMES } from 'config';

const history = createBrowserHistory();
export const { store, persistor } = configureStore({}, history);

function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={themeDefault}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <LanguageProvider messages={translationMessages}>
              <Router history={history}>
                <ReactNotification />
                <RouterWrap />
                <GlobalStyle />
              </Router>
            </LanguageProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;

const RouterWrap = ({ }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname) {
      const spilts = pathname.split("/");
      const name = ROUTENAMES[spilts[spilts.length - 1]];
      if (name) {
        window.dataLayer.push({
          'event': 'page_view',
          'page_environment': 'Espace client',
          'page_template': name,
          'page_path': window.location.href,
        });
      }
    }
  }, [pathname])
  return renderRoutes(routes)
}

